// settings
import Settings from '@/config/Settings';

// components
import Layout from '@/Views/_Layout/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import HeaderSmall from '@/Views/_Components/Header/Small/index.vue';

export default {
  name: 'Terms',

	components: {
		'oston-layout': Layout,
		'oston-help-menu': HelpMenu,
		'oston-header': HeaderSmall
	}
};
